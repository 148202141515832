@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  flex-direction: column-reverse;

  @include p-size-tablet {
    flex-direction: row;
    align-items: center;

    .imageContainer {
      width: 33%;
    }

    .textContainer {
      width: 60%;
    }
  }

  @include p-size-desktop {
    gap: 64px;
  }
}

.separator {
  margin-top: 4px;
  height: 4px;
  width: 0;
  background-color: $ds-purple-500;
}

.imageContainer {
  display: flex;

  img {
    width: 100%;
    height: auto;
  }
}

.imageContainer {
  @include p-size-desktop {
    order: 1;
  }
}

.textContainer {
  @include p-size-desktop {
    order: 2;
  }
}

.itemContainer {
  margin-top: 40px;

  @include p-size-tablet {
    margin-top: 48px;
  }
}

.left {
  @include p-size-desktop {
    order: 1;
  }
}

.right {
  @include p-size-desktop {
    order: 2;
  }
}
